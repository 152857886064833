<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-b">合作加盟</span>
    </div>
    <el-card class="el-main sceneCheckContent">
      <le-search-form
        id="leSearch"
        :can-pack-up="false"
        @reset="handlerRest"
        @search="handlerSearch"
      >
        <le-input
          v-model="pageParam.params.customerMobile"
          label="手机号码"
          maxlength="11"
          type="number"
          placeholder="请输入手机号码"
        />
        <le-input
          v-model="pageParam.params.customerName"
          label="联系人"
          maxlength="4"
          placeholder="请输入联系人"
        />
        <le-select-local-search
          v-model="pageParam.params.customerType"
          label="加盟类型"
          :options="[
            { value: '0', label: '公司' },
            { value: '1', label: '个人' },
          ]"
          placeholder="请选择加盟类型"
        />
        <le-date-range
          label="提交日期"
          :min-date.sync="pageParam.params.beginTime"
          :max-date.sync="pageParam.params.endTime"
        />
      </le-search-form>
      <le-pagview ref="sceneCheckList" :page-param="pageParam" @setData="setTableData">
        <el-table
          v-sticky="{ top: 0, parent: '.el-card__body' }"
          :data="tableData"
          empty-text=""
          :highlight-current-row="true"
          style="width: 100%"
          @filter-change="filterFun"
        >
          <el-table-column prop="__number__" label="序号"></el-table-column>
          <el-table-column prop="customerName" label="联系人"></el-table-column>
          <el-table-column prop="customerMobile" label="手机号"></el-table-column>
          <el-table-column
            prop="createTimeText"
            column-key="customerType"
            :filter-multiple="false"
            :filters="optionsCustomerType"
          >
            <template slot="header">
              <div class="a-flex-rfsc">
                <span>加盟类型</span>
                <img
                  style="width: 15px; height: 15px; margin-left: 7px"
                  src="../../assets/icon/filter-icon.png"
                  alt=""
                />
              </div>
            </template>
            <template slot-scope="{ row }">
              <span v-if="row.customerType === '0'">公司</span>
              <span v-else-if="row.customerType === '1'">个人</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="deviceNum" label="预计投资设备数量"></el-table-column>
          <el-table-column prop="companyName" label="意见城市" min-width="200">
            <template slot-scope="{ row }">
              <span>{{ row.province }}</span>
              <span>{{ row.city }}</span>
              <span>{{ row.area }}</span>
              <span>{{ row.address }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="提交时间" min-width="140"></el-table-column>
        </el-table>
        <template slot="footerLabel" slot-scope="scope">
          <div v-if="scope.val != -1" class="a-flex-rfsc">
            <span class="a-fs-12 a-c-normal">筛选出</span>
            <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{ scope.val }}</span>
            <span class="a-fs-12 a-c-normal a-plr-10">条记录</span>
          </div>
        </template>
      </le-pagview>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "CooperateList",

  data() {
    return {
      tableData: [],
      pageParam: {
        url: this.$Config.apiUrl.getCooperationList,
        method: "post",
        params: {
          customerName: "",
          customerMobile: "",
          customerType: "",
          beginTime: "",
          endTime: "",
        },
        freshCtrl: 1,
      },
      optionsCustomerType: [
        {
          text: "公司",
          value: 0,
        },
        {
          text: "个人",
          value: 1,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },
  created() {
    this.pageParam.params.currentCompanyId = this.company.id;
  },
  methods: {
    //获取设备列表
    setTableData(data) {
      this.tableData = data;
    },
    handlerRest() {
      this.pageParam.params = {
        customerName: "",
        customerMobile: "",
        customerType: "",
      };
      this.handlerSearch();
    },
    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    //条件筛选
    filterFun(value) {
      for (const key in value) {
        if (key == "customerType") {
          if (value[key].length == 0) {
            this.pageParam.params.customerType = "";
          } else {
            this.pageParam.params.customerType = value[key][0];
          }
        }
      }
      this.$refs["sceneCheckList"].pageNum = 1;
      this.handlerSearch();
    },
    // 查看现勘详情
    getInfo(datas) {
      this.$router.push({
        path: "/sceneCheck/sceneCheck-info",
        query: {
          id: datas.id,
          title: datas.areaName,
        },
      });
    },
    exportfile() {
      if (!this.fileUrl) {
        this.$message.warning("暂无可导出文件");
        return;
      }
      this.$confirm("是否确认导出文件？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$Utils.downloadUrlFile(this.fileUrl, "省份经营数据列表");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .sceneCheckContent {
  .el-table__column-filter-trigger {
    display: none;
  }
}
</style>
